<template>
  <div>
    <el-form
      :model="form"
      ref="form"
      :rules="rules"
      label-width="100px"
      :inline="false"
      size="normal"
    >
      <el-form-item label="版本号" prop="version">
        <el-input v-model="form.version" placeholder="请输入版本号"></el-input>
      </el-form-item>
      <el-form-item label="应用名称" prop="app_type">
        <el-select v-model="form.app_type" placeholder="请选择">
          <el-option v-for="item in appList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="检测周期" prop="detection_interval">
        <el-select v-model="form.detection_interval" placeholder="请选择">
          <el-option
            v-for="item in detectionList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="版本状态" prop="upgrade_type" type="number">
        <el-select v-model="form.upgrade_type" placeholder="请选择">
          <el-option v-for="item in upgradeList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="安卓下载地址" prop="download_link_android">
        <el-input
          v-model="form.download_link_android"
          placeholder="请输入安卓下载地址(apk后缀的地址)"
        ></el-input>
      </el-form-item>

      <el-form-item label="IOS下载地址" prop="download_link_ios">
        <el-input v-model="form.download_link_ios" placeholder="请输入IOS下载地址"></el-input>
      </el-form-item>
      <el-form-item label="中文更新描述" prop="description_cn">
        <el-input
          v-model="form.description_cn"
          type="textarea"
          :rows="4"
          placeholder="请输入中文更新描述"
        ></el-input>
      </el-form-item>
      <el-form-item label="英文更新描述" prop="description_en">
        <el-input
          v-model="form.description_en"
          type="textarea"
          :rows="4"
          placeholder="请输入英文更新描述"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="onSubmit">
          {{ type == "add" ? "立即创建" : "确认修改" }}
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addVersion, editVersion } from "@/api/nft.js";
import { VersionApp } from "@/utils/helper.js";
export default {
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: "add",
    },
  },

  data() {
    return {
      form: {
        status: -1,
      },
      appList: Object.keys(VersionApp).map((key) => ({
        id: key,
        name: VersionApp[key],
      })),
      detectionList: [
        { id: -1, name: "每次登陆" },
        { id: 1, name: "1天" },
        { id: 3, name: "3天" },
        { id: 7, name: "一周" },
        { id: 14, name: "两周" },
      ],
      upgradeList: [
        { id: "force", name: "强制升级" },
        { id: "prompt", name: "提示升级" },
      ],
      rules: {
        version: [{ required: true, message: "版本号不得为空", trigger: "blur" }],
        detection_interval: [{ required: true, message: "请选择检测周期", trigger: "change" }],
        app_type: [{ required: true, message: "请选择应用名称", trigger: "change" }],
        upgrade_type: [{ required: true, message: "请选择版本状态", trigger: "change" }],
      },
    };
  },
  mounted() {
    console.log("mounted", this.data);
    if (this.type === "edit") {
      this.form = Object.assign({}, this.data);
    }
  },
  methods: {
    onSubmit() {
      // console.log("onSubmit", { ...this.form });
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.submit();
        } else {
          console.log("onSubmitfalse", valid, this.form);
          return false;
        }
      });
    },
    async submit() {
      const action = this.type === "add" ? this.add : this.update;
      const success = await action();
      if (success) {
        this.$emit("success");
      }
    },

    async add() {
      try {
        const versionData = this.getVersionData();
        await addVersion(versionData);
        return true;
      } catch (error) {
        return false;
      }
    },
    async update() {
      try {
        const versionData = this.getVersionData();
        await editVersion(versionData);
        return true;
      } catch (error) {
        return false;
      }
    },

    getVersionData() {
      let json = {
        version: this.form.version,
        detection_interval: this.form.detection_interval, // 检测间隔天数
        upgrade_type: this.form.upgrade_type, // force - 强制升级， prompt - 提示升级
        download_link_android: this.form.download_link_android,
        download_link_ios: this.form.download_link_ios,
        description_cn: this.form.description_cn,
        description_en: this.form.description_en,
        app_type: this.form.app_type,
      };
      if (this.form.id) {
        json["id"] = this.form.id;
      }
      return json;
    },
  },
};
</script>
