<template>
  <div style="padding: 20px">
    <div>
      <el-row :gutter="20">
        <el-col :span="12" :offset="12">
          <div style="text-align: right; margin-bottom:10px">
            <el-button type="primary" size="default" @click="showAdd">
              新增
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <el-table :data="list" border stripe row-key="id">
      <el-table-column label="版本号" prop="version"></el-table-column>
      <el-table-column label="应用名称" prop="app_type">
        <template slot-scope="scope">
          {{ VersionApp[scope.row.app_type] }}
        </template>
      </el-table-column>
      <el-table-column label="版本状态" prop="upgrade_type">
        <template slot-scope="scope">
          {{ VersionUpgrade[scope.row.upgrade_type] }}
        </template>
      </el-table-column>
      <el-table-column label="更新描述" prop="description_cn"></el-table-column>
      <el-table-column label="安卓下载地址" prop="download_link_android"></el-table-column>
      <el-table-column label="IOS下载地址" prop="download_link_ios"></el-table-column>
      <el-table-column label="检测周期" prop="detection_interval">
        <template slot-scope="scope">
          {{ VersionDetection[scope.row.detection_interval] }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="260" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="showEdit(scope.row)"
            style="margin-right: 10px"
          >
            编辑
          </el-button>
          <el-button
            size="mini"
            type="success"
            @click="deleteVersion(scope.row.id)"
            style="margin-right: 10px"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 20px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog
      :title="optionType === 'add' ? '新增版本' : '编辑版本'"
      :visible.sync="visible"
      width="600px"
      @close="closeDialog"
      destroy-on-close
    >
      <div v-if="visible">
        <VersionForm :data="editVersion" :type="optionType" @success="handleSuccess" />
      </div>
    </el-dialog>
  </div>
</template>

<script>
import VersionForm from "./versionForm.vue";
import { getVersionList, deleteVersion } from "@/api/nft.js";
import { VersionDetection, VersionUpgrade, VersionApp } from "@/utils/helper.js";

export default {
  components: { VersionForm },
  data() {
    return {
      page: 1,
      pageSize: 20,
      total: 0,
      list: [],
      visible: false,
      optionType: "",
      editVersion: {},
      VersionDetection,
      VersionUpgrade,
      VersionApp,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const params = {
          page: this.page,
          page_size: this.pageSize,
        };
        const { data } = await getVersionList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    async deleteVersion(id) {
      try {
        const params = {
          id: id,
        };
        const { data } = await deleteVersion(params);
        this.page = 1;
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },

    closeDialog() {
      this.visible = false;
      this.editVersion = {};
      this.optionType = "";
    },

    showEdit(row) {
      this.visible = true;
      this.optionType = "edit";
      this.editVersion = row;
    },
    showAdd() {
      this.visible = true;
      this.optionType = "add";
      this.editVersion = {};
    },
    handleCurrentChange(page) {
      this.page = page;
      this.fetchData();
    },
    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      this.fetchData();
    },

    handleSuccess() {
      this.page = 1;
      this.fetchData();
      this.closeDialog();
    },
  },
};
</script>
